<template>
  <div
    :class="
      'col-xl-' +
      prodotto.colonne +
      ' col-md-' +
      prodotto.colonne +
      ' col-sm-12'
    "
  >
    <loading :active="isLoading" :is-full-page="false"></loading>
    <card
      :gradient="index % 2 === 0 ? 'info' : 'secondary'"
      class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
      :style="{ minHeight: '96%' }"
      header-classes="bg-transparent"
      :header-styles="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100px',
        backgroundImage: 'url(' + prodotto.urlImage + ')',
      }"
      body-classes="px-lg-7"
      footer-classes="bg-transparent"
    >
      <template v-slot:header>
        <h4
          :class="
            index % 2 === 0
              ? 'text-uppercase ls-1 text-white py-3 mb-0'
              : 'text-uppercase ls-1 text-black py-3 mb-0'
          "
        >
          {{ prodotto.descrizione }}
        </h4>
      </template>
      <div class="row" v-if="diagnosi">
        <div class="col-md-12">
          <h2 :class="index % 2 === 0 ? 'text-white mb-0' : 'text-black mb-0'">
            {{ decodeDiagnosiType(diagnosi.LogContatto.idlogtype) }}<br />
            Numero
            {{ diagnosi.numerounivoco }}<br />
            Redatta da
            {{ diagnosi.LogContatto.Users.username }}<br />
            il
            {{ formatDate(diagnosi.LogContatto.data_inserimento) }}<br />
            sono emersi i seguenti fabbisogni:
          </h2>
          <div class="table-responsive">
            <table class="table table-primary text-white table-bordered mt-3">
              <tbody>
                <tr>
                  <td>Premorienza</td>
                  <td>
                    {{ toCurrency(diagnosi.sommadaassicurarepremorienza) }}
                  </td>
                </tr>
                <tr>
                  <td>Invalidità</td>
                  <td>
                    {{ toCurrency(diagnosi.sommadaassicurareinvalidita) }}
                  </td>
                </tr>
                <tr>
                  <td>Non autosufficienza</td>
                  <td>
                    {{
                      toCurrency(diagnosi.sommadaassicurarenonautosufficienza)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <base-button
            type="primary"
            :class="index % 2 === 0 ? 'text-white' : 'text-black'"
            @click="openDiagnosi()"
            >SCARICA DIAGNOSI</base-button
          >
        </div>
      </div>

      <template v-slot:footer>
        <div v-if="!diagnosi">
          <span
            :class="
              index % 2 === 0 ? 'text-white text-left' : 'text-black text-left'
            "
            v-html="prodotto.descrizioneLunga"
          ></span>
          <base-alert type="warning">Nessuna diagnosi pubblicata</base-alert>
        </div>
        <div class="row">
          <div v-if="diagnosi" class="col-md-12 col-sm-12 mb-2">
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToStorico()"
              >Storico Diagnosi</base-button
            >
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="openContratto()"
              v-if="
                (contact.idclient === 99 || contact.idclient === 3) &&
                prodotto.haveContract
              "
              >Visualizza Contratto</base-button
            >
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToAgente()"
              >Il tuo Agente</base-button
            >
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import apiCall from "@/utils/api";
import { formatDate, toCurrency } from "@/filters/formatDate";
import Loading from "vue-loading-overlay";
import BaseAlert from "@/components/BaseAlert";
import { useStore } from "vuex";
import { computed, watch } from "vue";
import { runToast } from "@/util/notif";

export default {
  name: "DiagnosiFamigliaComponent",
  props: {
    prodotto: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    BaseAlert,
    Loading,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoading = ref(false);
    const diagnosi = ref(null);

    onMounted(async () => {
      await loadData();
    });

    const loadData = async function () {
      isLoading.value = true;
      let resp = await apiCall({
        url: "/private/diagnosi-famiglia/last",
        data: {},
        method: "GET",
      });

      diagnosi.value = resp;
      isLoading.value = false;
    };

    store.watch(
      (state) => state.user.prodotti,
      async () => {
        await loadData();
      }
    );

    const openDiagnosi = async function () {
      try {
        let resp = await apiCall({
          url:
            "/private/diagnosi-famiglia/getlink/" +
            diagnosi.value.idlogcontattodiagnosi,
          method: "GET",
        });
        window.open(resp.url, "_blank");
      } catch (e) {
        runToast("Errore download file", "top-center", "danger");
      }
    };

    const decodeDiagnosiType = function (type) {
      switch (type) {
        case 11:
          return "Diagnosi Futuro senza sorprese".toUpperCase();
        case 22:
          return "Diagnosi Futuro senza sorprese".toUpperCase();
        case 12:
          return "Contributi senza sorprese".toUpperCase();
        case 23:
          return "Contributi senza sorprese".toUpperCase();
      }
    };

    const openContratto = async function () {
      try {
        let resp = await apiCall({
          url: "/private/diagnosi-famiglia/contratto",
          method: "GET",
        });
        window.open(resp.url, "_blank");
      } catch (e) {
        runToast("Errore download file", "top-center", "danger");
      }
    };

    const goToHome = function () {
      router.push("/");
    };

    const goToStorico = function () {
      router.push("/storico-diagnosi-famiglia");
    };

    const goToAgente = function () {
      router.push("/agente");
    };

    watch(
      () => store.state.user.contact,
      function () {}
    );

    return {
      contact: computed(() => store.state.user.contact),
      diagnosi,
      isLoading,
      openContratto,
      openDiagnosi,
      decodeDiagnosiType,
      formatDate,
      toCurrency,
      goToHome,
      goToStorico,
      goToAgente,
    };
  },
};
</script>
